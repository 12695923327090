var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" Company's Address(es) "),_c('div',[_c('b',[_vm._v("Our Company:")]),_vm._v("   "),_c('span',{staticClass:"tex-bold"},[_vm._v(_vm._s(_vm.$store.getters.getAddressListOurCompany)),_c('br')]),_c('b',[_vm._v("Customer:")]),_vm._v("   "+_vm._s(_vm.$store.getters.getAddressListCustomerInfo)+" ")]),_c('br'),_c('div',[(!_vm.showAddAddressForm)?_c('customer-address-list',{key:_vm.$store.getters.getAddressListKey}):_vm._e()],1),(!_vm.hideOtherFields)?_c('div',[(_vm.showAddAddressForm)?_c('b-button',{staticClass:"is-primary button-style width-200",on:{"click":_vm.onClose}},[_vm._v("X Close")]):_vm._e()],1):_vm._e(),(_vm.showAddAddressForm)?_c('vue-form-generator',{attrs:{"schema":_vm.schemaAddress,"model":_vm.modelAddress,"options":_vm.formOptionsAddress}}):_vm._e(),_c('div',[(
				_vm.showAddAddressForm &&
				_vm.$store.getters.getAddressAddEditToggle === 'add' &&
				!_vm.hideOtherFields
			)?_c('b-button',{staticClass:"is-info button-style width-200",on:{"click":_vm.onSave}},[_vm._v("Add Address")]):_vm._e()],1),_c('div',[(
				_vm.showAddAddressForm &&
				_vm.$store.getters.getAddressAddEditToggle === 'edit' &&
				!_vm.hideOtherFields
			)?_c('b-button',{staticClass:"is-info button-style",on:{"click":_vm.onUpdate}},[_vm._v("Update Address")]):_vm._e()],1),(_vm.hideOtherFields)?_c('div',[_c('div',[_c('b-button',{staticClass:"is-success button-style width-200",on:{"click":_vm.onAddNewCity}},[_vm._v("Add New City")]),_c('b-button',{staticClass:"is-danger button-style margin-left-20 width-200",on:{"click":_vm.onUnhide}},[_vm._v("Cancel")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }