<!-- @format -->

<template>
	<div>
		<div>
			<ul>
				<li
					v-for="item of addressLinesResult"
					:key="item.address_id"
					class="list-item"
				>
					<b-button
						class="is-danger small-btn width-200"
						size="is-small"
						@click="onDelete(item.address_id)"
					>
						Delete
					</b-button>
					<b-button
						class="is-info small-btn width-200"
						size="is-small"
						@click="onEdit(item)"
					>
						Edit
					</b-button>
					<div>
						<b>Address Nickname:&nbsp;</b>{{ item.address_nickname }}<br />
						<b>Address Type:&nbsp;</b>{{ item.address_type }}<br />
						<b>Route Id:&nbsp;</b>{{ item._cities.route_id_by_city }}<br />
						<b>{{ item.line_1 }}</b> &nbsp; <b>{{ item.line_2 }}</b
						><br />
						<b>{{ item.city }} &nbsp; {{ item.post_code }}</b
						>&nbsp; <b>{{ item.state }} &nbsp; {{ item.country }}</b
						><br /><br /><br />
					</div>
				</li>
			</ul>
			<div>
				<b-button class="is-success small-btn width-200" @click="onAddAddress">
					Add new address
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import CompanyAddressesListSubscription from './CompanyAddressesListSubscription.graphql'
import DeleteCustomerAddressMutation from './DeleteCustomerAddressMutation.graphql'
import { useSubscription, useResult, useMutation } from '@vue/apollo-composable'
import useAddressFormState from './useAddressForm'
import useCompaniesIdentityDetailsState from './useCompaniesIdentyDetailsState'
import Store from '@/store'
import { getCurrentInstance } from '@vue/composition-api'

export default {
	name: 'CustomerAddressList',
	setup() {
		const { result } = useSubscription(
			CompanyAddressesListSubscription,
			() => ({
				owner_id: Store.getters.getAddressListCustomer,
				company_id: Store.getters.getAddressListOurCompany,
			}),
		)
		const addressLinesResult = useResult(result, null, (data) => data.addresses)
		const {
			addressId,
			companyId,
			ownerId,
			addressNickname,
			addressType,
			routeId,
			line1,
			line2,
			city,
			postCode,
			state,
			country,
			addressIsActive,
			listGroup,
			orderClerk,
			addressPhone,
			openHours,
		} = useAddressFormState()
		const { addressToggle } = useCompaniesIdentityDetailsState()
		const onEdit = (item) => {
			console.log('******* item', item)
			addressId.value = item.address_id
			companyId.value = item.company_id
			ownerId.value = item.owner_id
			addressNickname.value = item.address_nickname
			addressType.value = item.address_type
			routeId.value = item._cities.route_id_by_city
			line1.value = item.line_1
			line2.value = item.line_2
			city.value = item.city
			postCode.value = item.post_code
			state.value = item.state
			country.value = item.country
			addressIsActive.value = item.is_active
			listGroup.value = item.list_group
			orderClerk.value = item.order_clerk
			addressPhone.value = item.address_phone
			openHours.value = item.open_hours
			addressToggle.value = true
			Store.dispatch('setAddressAddEditToggle', 'edit')
			Store.dispatch('setAddressListKey', Store.getters.getAddressListKey + 1)
			console.log('selected company', item)
		}
		const onAddAddress = () => {
			companyId.value = Store.getters.getAddressListOurCompany
			ownerId.value = Store.getters.getAddressListCustomer
			addressNickname.value = 'till fakturans adress'
			addressType.value = ''
			routeId.value = ''
			line1.value = ''
			line2.value = ''
			city.value = ''
			postCode.value = ''
			state.value = 'Skåne'
			country.value = 'Sverige'
			addressIsActive.value = true
			listGroup.value = ''
			orderClerk.value = ''
			addressPhone.value = ''
			openHours.value = 'mån , tis , ons , tor , fre , lör , sön'
			Store.dispatch('setAddressAddEditToggle', 'add')
			Store.dispatch('setAddressListKey', Store.getters.getAddressListKey + 1)
			// -------------------------------------------------
			addressToggle.value = true
		}
		// Delete address
		const vm = getCurrentInstance()
		const alertCustom = (t, m) => {
			vm.$buefy.dialog.alert({
				title: t,
				message: m,
				confirmText: 'Continue!',
			})
		}
		const {
			mutate: deleteAddress,
			onDone: onDoneDelete,
			onError: onErrorDelete,
		} = useMutation(DeleteCustomerAddressMutation)
		const onDelete = (addressId) => {
			deleteAddress({
				address_id: addressId,
			})
		}
		onDoneDelete((result) => {
			// console.log('***', result.data.insert_customers)
			// let resArr = result.data.insert_customers.returning[0]
			// let popUpMsg = JSON.stringify(resArr)
			console.log('result onDone', result)
			alertCustom('The item has been saved')
		})
		onErrorDelete(() => {
			const errMessage = `
      Something went wrong.
      Item can not be saved check the data you have entered!!!
      `
			alertCustom('Error', errMessage)
		})
		return {
			addressLinesResult,
			onEdit,
			onDelete,
			onAddAddress,
		}
	},
}
</script>

<style scoped>
.small-btn {
	margin-right: 15px;
}
.margin-top-20 {
	margin-top: 20px;
}
</style>
