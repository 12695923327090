<!-- @format -->

<template>
	<div>
		Company's Address(es)
		<div>
			<b>Our Company:</b> &nbsp;
			<span class="tex-bold"
				>{{ $store.getters.getAddressListOurCompany }}<br />
			</span>
			<b>Customer:</b> &nbsp; {{ $store.getters.getAddressListCustomerInfo }}
		</div>
		<br />
		<div>
			<customer-address-list
				:key="$store.getters.getAddressListKey"
				v-if="!showAddAddressForm"
			/>
		</div>
		<div v-if="!hideOtherFields">
			<b-button
				class="is-primary button-style width-200"
				@click="onClose"
				v-if="showAddAddressForm"
				>X Close</b-button
			>
		</div>
		<vue-form-generator
			v-if="showAddAddressForm"
			:schema="schemaAddress"
			:model="modelAddress"
			:options="formOptionsAddress"
		>
		</vue-form-generator>
		<div>
			<b-button
				class="is-info button-style width-200"
				@click="onSave"
				v-if="
					showAddAddressForm &&
					$store.getters.getAddressAddEditToggle === 'add' &&
					!hideOtherFields
				"
				>Add Address</b-button
			>
		</div>
		<div>
			<b-button
				class="is-info button-style"
				@click="onUpdate"
				v-if="
					showAddAddressForm &&
					$store.getters.getAddressAddEditToggle === 'edit' &&
					!hideOtherFields
				"
				>Update Address</b-button
			>
		</div>
		<div v-if="hideOtherFields">
			<div>
				<b-button
					class="is-success button-style width-200"
					@click="onAddNewCity"
					>Add New City</b-button
				>
				<b-button
					class="is-danger button-style margin-left-20 width-200"
					@click="onUnhide"
					>Cancel</b-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import useAddressFormState from '@/_srcv2/views/pages/admin-reports/customer-list/add-address/useAddressForm.js'
import useCompaniesIdentityDetailsState from './useCompaniesIdentyDetailsState'

import { computed, getCurrentInstance, onMounted } from '@vue/composition-api'

import { useMutation } from '@vue/apollo-composable'
import AddCustomerAddressMutation from './AddCustomerAddressMutation.graphql'
import UpdateCustomerAddressMutation from './UpdateCustomerAddressMutation.graphql'
import AddNewCityMutation from './AddNewCityMutation.graphql'
import CustomerAddressList from './CustomerAddressList.vue'

export default {
	name: 'AddCustomerAddress',
	components: { CustomerAddressList },
	setup: function () {
		// Get the form from composable
		const {
			modelAddress,
			schemaAddress,
			formOptionsAddress,
			hideOtherFields,
			getDropdownData,
		} = useAddressFormState()
		const { addressToggle, selectedCompanyId } =
			useCompaniesIdentityDetailsState()
		const showAddAddressForm = computed(() => addressToggle.value)
		const selectedOwner = computed(() => selectedCompanyId.value)
		modelAddress.companyId = Store.getters.getUserCurrentCompany
		modelAddress.ownerId = selectedOwner.value
		// const onAddNewAddress = () => {
		//   addressToggle.value = true
		// }
		onMounted(() => {
			console.log('model address', modelAddress)
		})
		const onClose = () => {
			addressToggle.value = false
		}
		// ** Create uuid for address-id
		const vm = getCurrentInstance()
		const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'

		function getUUID() {
			let TIMEPARAMETER = (
				Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
			).toString()
			return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
		}
		// ** Add new Address
		const { mutate: addNewAddress, onError } = useMutation(
			AddCustomerAddressMutation,
			() => ({
				variables: {
					address_id: getUUID(),
					company_id: modelAddress.companyId,
					owner_id: modelAddress.ownerId,
					address_nickname: modelAddress.addressNickname,
					address_type: modelAddress.addressType,
					line_1: modelAddress.line1,
					line_2: modelAddress.line2,
					city: modelAddress.city,
					post_code: modelAddress.postCode,
					state: modelAddress.state,
					country: modelAddress.country,
					is_active: modelAddress.addressIsActive,
					list_group: modelAddress.listGroup,
					address_phone: modelAddress.addressPhone,
					order_clerk: modelAddress.orderClerk,
					open_hours: modelAddress.openHours,
				},
			}),
		)
		const onSave = () => {
			addNewAddress()
			onClose()
		}
		const alertCustom = (t, m) => {
			vm.$buefy.dialog.alert({
				title: t,
				message: m,
				confirmText: 'Continue!',
			})
		}
		onError(() => {
			const errMessage = `
	     Something went wrong.
	     Item can not be saved check the data you have entered!!!
	     `
			alertCustom('Error', errMessage)
		})
		// Update address
		const { mutate: updateAddress, onError: onErrorUpdate } = useMutation(
			UpdateCustomerAddressMutation,
			() => ({
				variables: {
					address_id: modelAddress.addressId,
					company_id: modelAddress.companyId,
					owner_id: modelAddress.ownerId,
					address_nickname: modelAddress.addressNickname,
					address_type: modelAddress.addressType,
					// route_id: modelAddress.routeId,
					line_1: modelAddress.line1,
					line_2: modelAddress.line2,
					city: modelAddress.city,
					post_code: modelAddress.postCode,
					state: modelAddress.state,
					country: modelAddress.country,
					is_active: modelAddress.addressIsActive,
					list_group: modelAddress.listGroup,
					address_phone: modelAddress.addressPhone,
					order_clerk: modelAddress.orderClerk,
					open_hours: modelAddress.openHours,
				},
			}),
		)
		const onUpdate = () => {
			updateAddress()
			onClose()
		}
		onErrorUpdate(() => {
			const errMessage = `
	     Something went wrong.
	     Item can not be saved check the data you have entered!!!
	     `
			alertCustom('Error', errMessage)
		})
		// ** ----------------------------------------------------
		const onUnhide = () => {
			hideOtherFields.value = false
			modelAddress.city = null
		}
		// ** --------------------------------------------------------
		// ! Add new city
		const { mutate: addNewCity, onError: onErrorAddNewCity } = useMutation(
			AddNewCityMutation,
			() => ({
				variables: {
					city_name: computed(() => modelAddress.newCityName).value,
					route_id_by_city: computed(() => modelAddress.newRouteId).value,
				},
			}),
		)
		const onAddNewCity = () => {
			addNewCity()
				.then((result) => {
					alert(
						`The city "${result.data.insert_addresses_cities.returning[0].city_name}" is added to route "${result.data.insert_addresses_cities.returning[0].route_id_by_city}"`,
					)
				})
				.then(() => onUnhide())
				.then(() => {
					getDropdownData()
				})
				.then(() => {
					modelAddress.city = modelAddress.newCityName
					modelAddress.routeId = modelAddress.newRouteId
				})
		}
		onErrorAddNewCity(() => alert('City can not be added to list!'))
		return {
			modelAddress,
			schemaAddress,
			formOptionsAddress,
			showAddAddressForm,
			hideOtherFields,
			onUnhide,
			onClose,
			onSave,
			onUpdate,
			onAddNewCity,
		}
	},
}
</script>

<style scoped>
.button-style {
	margin-bottom: 15px;
	margin-top: 15px;
}
</style>
