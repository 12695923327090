<template>
	<div style="padding-top: 50px">
		<div>
			<form id="set-customer-details">
				<vue-form-generator
					@validated="onFormValidated"
					:schema="schemaCompanies"
					:model="modelCompanies"
					:options="formOptionsCompanies"
				>
				</vue-form-generator>
			</form>
		</div>
	</div>
</template>

<script>
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'

export default {
	name: 'SetCustomerDetails',
	setup() {
		const { schemaCompanies, modelCompanies, formOptionsCompanies } =
			useCustomerDetailsForm()
		const onFormValidated = () => 2
		return {
			schemaCompanies,
			modelCompanies,
			formOptionsCompanies,
			onFormValidated,
		}
	},
}
</script>

<style scoped></style>
