import { render, staticRenderFns } from "./CustomerDetailsForm.vue?vue&type=template&id=f85db922&scoped=true&"
import script from "./CustomerDetailsForm.vue?vue&type=script&lang=js&"
export * from "./CustomerDetailsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85db922",
  null
  
)

export default component.exports